import "./spaceship.scss";

function Spaceship() {
    return (
        <div className="spaceship">
            <div className="helmet"></div>
            <div className="cargo"></div>
            <div className="magnet"></div>
        </div>
    );
}

export default Spaceship;
