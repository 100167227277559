// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyDVBYoVF3YLM4G9uSiaG6oGQ9uoztFxdxs",
    authDomain: "mathlabfun.firebaseapp.com",
    projectId: "mathlabfun",
    storageBucket: "mathlabfun.appspot.com",
    messagingSenderId: "813261118335",
    appId: "1:813261118335:web:3b3100da1997a1dd3f584a",
    measurementId: "G-7GYFQSRB76",
};
